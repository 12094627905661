import { ContentItem } from '@common/services/api/searchServices/_contentSearchService';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ContentState {
  selectedContents: ContentItem[] | null;
}

export const initialState: ContentState = {
  selectedContents: null,
};

const contentSlice = createSlice({
  name: 'content',
  initialState: initialState,
  reducers: {
    setSelectedItems: (state, action: PayloadAction<ContentItem[]>) => {
      state.selectedContents = action.payload;
    },
  },
});

export const { setSelectedItems } = contentSlice.actions;
export default contentSlice.reducer;

// Selectors
export const getContentItems = (state: { content: ContentState }) => state.content.selectedContents;
