import './index.css';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from '../src/common/store/store';
import App from './App';
import AuthProvider from './AuthProvider';
import { msalConfig } from './msal.config';
import reportWebVitals from './reportWebVitals';

/**
 * MSAL should be instantiated outside of the component tree to prevent it
 * from being re-instantiated on re-renders.
 */
export const pca = new PublicClientApplication(msalConfig);
export const msalInitializedPromise = pca.initialize(); // Create a promise for initialization

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <Provider store={store}>
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
);

reportWebVitals();
