import { useGetUserProfileQuery } from '@/services/apiWeb/profileServices/userProfile';
import { useMsal } from '@azure/msal-react';
import Container from '@components/container';
import CustomModal from '@components/customModal';
import NavbarWithMenu from '@components/navbarWithMenu';
import Spinner from '@components/spinner';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import logoutIcon from '../../common/assets/images/mygrdc/logout.svg';
import profileOutline from '../../common/assets/images/mygrdc/profile-outline.svg';
import Footer from '../dashboard/footer';
import CropSelection from './cropsSelection';
import DeactivateAccount from './deactivateAccount';
import MainContent from './mainContent';
import PersonalDetails from './personalDetails';
import Preferences from './preferences';
import TopicsSelection from './topicsSelection';

const Profile: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const { instance } = useMsal();
  const navigate = useNavigate();

  const {
    isLoading: userProfileLoading,
    isFetching,
    refetch,
  } = useGetUserProfileQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    refetch();
  }, [location, refetch]);

  const confirmLogOut = () => {
    instance
      .logoutRedirect()
      .catch((error: any) => console.error('Logout error:', error));
    navigate('/');
  };

  const isCropsOrTopicsPage =
    location.pathname.startsWith('/profileSummary/crops') ||
    location.pathname.startsWith('/profileSummary/topics');

  return (
    <Container navbar={<NavbarWithMenu />}>
      {userProfileLoading ? (
        <Spinner />
      ) : (
        <div
          id="profile-page"
          className="flex flex-col min-h-screen bg-white-50 pt-2 shadow-inner"
        >
          <div className="flex flex-1">
            <aside
              id="sidebar"
              className="w-1/4 bg-white pt-14 sm:p-0 pl-3 md:p-12 md:pl-12 lg:p-12 xl:pl-28"
            >
              <ul id="sidebar-menu" className="space-y-4">
                <li>
                  <button
                    id="profile-button"
                    className="flex items-center w-full text-left p-2 rounded-lg bg-primaryTeal-100 h-12"
                  >
                    <span className="material-icons text-green-400 mr-2">
                      <img
                        src={profileOutline}
                        alt="profile outline icon"
                        className="w-6 h-6"
                      />
                    </span>
                    <span className="font-semiBold">Profile</span>
                  </button>
                </li>

                <li>
                  <button
                    id="logout-button"
                    className="flex items-center w-full text-left p-2 rounded-lg hover:bg-neutral-100"
                    onClick={() => setIsModalOpen(true)}
                  >
                    <span className="material-icons mr-2">
                      <img
                        src={logoutIcon}
                        alt="logout icon"
                        className="w-6 h-6"
                      />
                    </span>
                    <span className="font-semiBold text-neutral-600">
                      Log Out
                    </span>
                  </button>
                </li>
              </ul>
            </aside>

            <main id="main-content" className="flex-1 p-12 bg-white">
              <Routes>
                <Route
                  path="/"
                  element={
                    isFetching || userProfileLoading ? (
                      <Spinner />
                    ) : (
                      <MainContent />
                    )
                  }
                />
                <Route path="personalDetails" element={<PersonalDetails />} />
                <Route path="preferences" element={<Preferences />} />
                <Route
                  path="deactivateAccount"
                  element={<DeactivateAccount />}
                />
                <Route path="crops" element={<CropSelection />} />
                <Route path="topics" element={<TopicsSelection />} />
              </Routes>
            </main>
          </div>
          <div className={isCropsOrTopicsPage ? 'pb-28' : ''}>
            <Footer />
          </div>
        </div>
      )}

      <CustomModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Log Out?"
      >
        <>
          <div id="logout-modal-content" className="py-1 mb-1">
            <div
              id="logout-modal-header"
              className="flex items-center justify-between pb-2 mb-3 border-b"
            ></div>
            <span
              id="logout-modal-message"
              className="font-regular text-right text-neutral-800"
            >
              Are you sure you want to log out?
            </span>
          </div>
          <div
            id="logout-modal-actions"
            className="flex flex-col-reverse sm:flex-row justify-end gap-4 mt-8"
          >
            <button
              id="logout-modal-cancel-button"
              onClick={() => setIsModalOpen(false)}
              className="px-6 py-2 border-2 border-primary-400 text-primary-400 font-semibold rounded-lg hover:bg-primary-50"
            >
              Cancel
            </button>
            <button
              id="logout-modal-confirm-button"
              onClick={confirmLogOut}
              className="px-6 py-2 bg-primary-400 text-white font-semibold rounded-lg hover:bg-primary-500"
            >
              Logout
            </button>
          </div>
        </>
      </CustomModal>
    </Container>
  );
};

export default Profile;
