import { pca } from '../../index';

import Button from '@components/button';
import Carousel from '@components/carousel';
import Container from 'src/components/container';
import TermsAndConditions from './tnC';

import { loginRequest } from '../../msal.config';
import { carouselData, welcomeText } from './data';

const Welcome = () => {
  const handleLogin = async () => {
    try {
      const accounts = pca.getAllAccounts();

      if (accounts.length > 0) {
        pca.setActiveAccount(accounts[0]);
      } else {
        await pca.loginRedirect({ ...loginRequest });
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const handleCreateAccount = async () => {
    try {
      await pca.loginRedirect({
        ...loginRequest,
        prompt: 'select_account',
        redirectUri: '/',
      });

      // Ensure the session is registered properly
      const accounts = pca.getAllAccounts();
      if (accounts.length > 0) {
        pca.setActiveAccount(accounts[0]);
      }
    } catch (error) {
      console.error('Create account error:', error);
    }
  };

  return (
    <Container>
      <div className="flex flex-col items-center flex-1 mt-16">
        <div className="flex flex-col items-center justify-center">
          <div className="text-5xl font-bold text-center">{welcomeText}</div>
          <div className="w-[18rem] mt-2 flex flex-col font-regular justify-center items-center">
            <Carousel items={carouselData} />
            <div className="flex flex-col font-bold gap-4 mt-16">
              <Button title="Sign in" onClick={handleLogin} />
              <Button title="Create account" onClick={handleCreateAccount} />
            </div>
          </div>
          <div className="px-4 py-10 mt-10">
            <TermsAndConditions />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Welcome;
