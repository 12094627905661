import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import QualtricsFeedback from '@components/qualtricsFeedback';
import ScreenNotification from '@components/screenNotification';
import Spinner from '@components/spinner';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import '../src/App.css';
import ProtectedRoute from './components/protectedRoute';
import AllLatestNewsPage from './pages/dashboard/allLatestNews';
import AllLatestReleasePage from './pages/dashboard/allLatestReleasesPage';
import AllLatestVideosAndPodcasts from './pages/dashboard/allLatestVideosAndPodcasts';
import Dashboard from './pages/dashboard/dashboard';
import Saved from './pages/dashboard/saved';
import Search from './pages/dashboard/search';
import NoPermission from './pages/noPermission/noPermission';
import NotFound from './pages/notFound/notFound';
import Profile from './pages/profile/profile';
import Registration from './pages/registration/registration';
import Welcome from './pages/welcome/welcome';
import { useGetUserProfileQuery } from './services/apiWeb/profileServices/userProfile';

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  const { data: userProfile } = useGetUserProfileQuery();

  useEffect(() => {
    if (userProfile) {
      setIsLoading(false);
    }
  }, [userProfile]);

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-grow">
        <ScreenNotification />
        <AuthenticatedTemplate>
          {isLoading && <Spinner />}
          <QualtricsFeedback />

          {!isLoading && (
            <Routes>
              <Route path="/registration" element={<Registration />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/search" element={<Search />} />
                <Route path="/saved" element={<Saved />} />
                <Route path="/allLatestNews" element={<AllLatestNewsPage />} />
                <Route path="/profileSummary/*" element={<Profile />}></Route>

                <Route
                  path="/allLatestReleases"
                  element={<AllLatestReleasePage />}
                />
                <Route
                  path="/allLatestVideosAndPodcasts"
                  element={<AllLatestVideosAndPodcasts />}
                />
              </Route>
              <Route path="/no-permission" element={<NoPermission />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Welcome />
        </UnauthenticatedTemplate>
      </div>
    </div>
  );
};

export default App;
