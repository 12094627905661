import { useGetLatestReleasesQuery } from '@/services/apiWeb/searchServices/latestReleases';
import ImageBanner from '@components/imageBanner';
import PageHeading from '@components/pageHeading';
import Pagination from '@components/pagination';
import ResultsCount from '@components/resultsCount';
import ResultsList from '@components/resultsList';
import SortingDropdown from '@components/sortingDropdown';
import React, { useEffect, useMemo, useState } from 'react';
import latestReleasesHomePageImage from '../../common/assets/images/mygrdc/latest-releases.png';
import Container from '../../components/container';
import NavbarWithMenu from '../../components/navbarWithMenu';
import Footer from './footer';

const AllLatestReleasesPage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOption, setSelectedOption] = useState('Relevance');
  const itemsPerPage = 10;

  const sortingOptions = [
    { label: 'Relevance', value: 0 },
    { label: 'Newest to oldest', value: 2 },
    { label: 'Oldest to newest', value: 1 },
  ];

  const currentSortOption = useMemo(() => {
    return sortingOptions.find((option) => option.label === selectedOption)
      ?.value;
  }, [selectedOption]);

  const { data: releases } = useGetLatestReleasesQuery(
    {
      sortBy: currentSortOption,
      pageNumber: currentPage + 1,
      pageSize: itemsPerPage,
    },
    {
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    },
  );

  const currentItems = releases?.items ?? [];

  const totalItems = releases?.totalCount ?? 0;
  const pageCount = Math.ceil(totalItems / itemsPerPage);
  const startItem = currentPage * itemsPerPage + 1;
  const endItem = Math.min((currentPage + 1) * itemsPerPage, totalItems);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!releases || releases.totalCount === 0) {
    return null;
  }

  const handleOptionSelect = (option: { label: string; value: number }) => {
    setSelectedOption(option.label);
  };

  const handlePageClick = (selectedPage: number) => {
    if (selectedPage !== currentPage) {
      setCurrentPage(selectedPage);
    }
  };

  return (
    <div id="all-latest-releases">
      <Container navbar={<NavbarWithMenu />}>
        <div className="bg-white">
          <ImageBanner
            imageSrc={latestReleasesHomePageImage}
            altText={'Latest resources'}
          />

          <PageHeading
            title="Latest resources"
            description="The results below are recently published or updated publications,
              GrowNotes, and update papers, curated by your profile settings."
          />

          <div className="mt-8 px-16 md:px-68 lg:px-68 xl:px-72">
            <div className="flex justify-between items-center mb-6 bg-neutral-100 rounded-lg">
              <ResultsCount
                startItem={startItem}
                endItem={endItem}
                totalItems={totalItems}
                highlightText={'latest resources'}
              />
              <SortingDropdown
                selectedOption={selectedOption}
                sortingOptions={sortingOptions}
                onSelectOption={handleOptionSelect}
              />
            </div>

            <ResultsList items={currentItems} />

            {pageCount > 1 && (
              <Pagination
                currentPage={currentPage}
                pageCount={pageCount}
                onPageChange={handlePageClick}
              />
            )}
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default AllLatestReleasesPage;
