const LatestNewsSkeletons: React.FC = () => {
  const skeletonItems = Array.from({ length: 3 }); // Adjust the number of skeleton items

  return (
    <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-4 mt-16 mb-16">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-8">
        <div className="bg-secondaryTeal-100 bg-cover bg-no-repeat h-8 w-56 sm:w-80 rounded bg-shimmer animate-shimmer"></div>
        <div className="hidden lg:block bg-secondaryTeal-100 bg-cover bg-no-repeat h-10 w-40 rounded bg-shimmer animate-shimmer"></div>
      </div>

      {/* Grid and Button Section */}
      <div className="flex flex-col lg:flex-row">
        {/* Skeleton Grid Section */}
        <div className="flex-grow grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {skeletonItems.map((_, index) => (
            <div
              key={index}
              className="bg-secondaryTeal-100 bg-cover bg-no-repeat shadow-lg rounded-lg overflow-hidden flex flex-col justify-between h-full bg-shimmer animate-shimmer"
            >
              {/* Image Skeleton */}
              <div className="w-full h-28 md:h-40"></div>

              {/* Text Skeleton */}
              <div className="p-4 flex flex-col justify-between flex-grow"></div>
            </div>
          ))}
        </div>
      </div>

      {/* Mobile View Button */}
      <div className="flex justify-center lg:hidden mt-8">
        <div className="px-6 py-3 bg-secondaryTeal-100 bg-cover bg-no-repeat font-bold rounded-md bg-shimmer animate-shimmer h-10 w-full"></div>
      </div>
    </div>
  );
};

export default LatestNewsSkeletons;
