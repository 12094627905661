import COMMON_PROPERTIES from '@common/properties/common-properties';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getAccessToken } from '@/auth';
import {
  CommonRequest,
  ContentSearchServiceClient,
  SearchResponse,
} from '@common/services/api/searchServices/_contentSearchService';

const baseAPIUrl = COMMON_PROPERTIES.baseAPIUrl;
const subscriptionKey = COMMON_PROPERTIES.subscriptionKey ?? '';
const client = new ContentSearchServiceClient(
  baseAPIUrl + '/contentsearchservice',
);

export const pastEventsApi = createApi({
  reducerPath: 'pastEventsApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getPastEvents: builder.query<SearchResponse, CommonRequest>({
      queryFn: async (
        body,
      ): Promise<{ data?: SearchResponse; error?: any }> => {
        const token = await getAccessToken();

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const upcomingEvents = await client.getPastEventsFunction(
            body,
            token,
            subscriptionKey,
          );
          return { data: upcomingEvents };
        } catch (error: any) {
          return {
            error: {
              status: error.response?.status || 500,
              data: error.response?.data || 'Unknown error occurred',
            },
          };
        }
      },
    }),
  }),
});

export const { useGetPastEventsQuery } = pastEventsApi;
