import React from 'react';
import rightGreen from '../../common/assets/images/mygrdc/chevron-right-green.svg';

import { useGetNewsQuery } from '@/services/apiWeb/searchServices/news';
import LatestNewsSkeletons from '@/skeletons/latestNewsSkeletons';
import { formatDateRegion, getFormattedUrl } from '@/utils/helpers';
import { useNavigate } from 'react-router-dom';

const LatestNews: React.FC = () => {
  const navigate = useNavigate();

  const { data: newsData, isFetching } = useGetNewsQuery(
    {
      pageNumber: 1,
      pageSize: 3,
    },
    {
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    },
  );

  if (isFetching) {
    return <LatestNewsSkeletons />;
  }

  if (!newsData || newsData?.totalCount === 0) {
    return null;
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-4 mt-8 mb-8">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-3xl font-bold text-neutral-900 ">Latest news</h2>
        <button
          onClick={() => navigate('/allLatestNews')}
          className="hidden lg:block px-6 py-3 bg-primary-400 text-white text-lg font-bold rounded-md hover:bg-primary-500 transition-all"
        >
          See all latest news
        </button>
      </div>

      {/* Grid and Button Section */}
      <div className="flex flex-col lg:flex-row">
        {/* Grid Section */}
        <div className="flex-grow grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {newsData?.items?.map((item, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col justify-between h-full"
            >
              <img
                src={
                  item.document?.images?.thumbnail
                    ? item.document.images.thumbnail.startsWith('http')
                      ? item.document.images.thumbnail
                      : `https://${item.document.images.thumbnail}`
                    : ''
                }
                alt={item.document?.title}
                className="w-full h-28 md:h-40 object-cover object-top"
              />
              <div className="p-4 flex flex-col justify-between flex-grow">
                <div>
                  <p className="text-sm text-neutral-600 font-semibold">
                    {formatDateRegion(
                      item?.document?.datePublished,
                      item?.document?.region,
                    )}
                  </p>
                  <h5 className="mt-2 text-xl font-bold line-clamp-4">
                    {item.document?.title}
                  </h5>
                </div>
                {item.document?.url && (
                  <a
                    href={
                      getFormattedUrl(
                        item?.document?.url,
                        item?.document?.type,
                      ) ?? '#'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary-400 font-bold mt-2 flex items-center space-x-1"
                  >
                    <span>Read more</span>
                    <img
                      src={rightGreen}
                      alt="Chevron Right"
                      className="w-6 h-6"
                    />
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Mobile View Button */}
        <div className="flex justify-center lg:hidden">
          <button
            onClick={() => navigate('/allLatestNews')}
            className="px-6 py-3 mt-8 bg-primary-400 text-white font-bold rounded-md hover:bg-green-800 w-full"
          >
            See all latest news
          </button>
        </div>
      </div>
    </div>
  );
};

export default LatestNews;
