import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BookmarkResponseInfo,
  BookmarkServiceClient,
  CreateBookmarkPayload,
  GetBookmarksRequest,
  GetBookmarksResponse,
  RemoveBookmarksPayload,
  RemoveBookmarksResponseInfo,
} from './_bookmarkService';
import COMMON_PROPERTIES from '@common/properties/common-properties';
import { RootState } from '@common/store/store';

const baseAPIUrl = COMMON_PROPERTIES.baseAPIUrl;
const subscriptionKey = COMMON_PROPERTIES.subscriptionKey || '';
const client = new BookmarkServiceClient(baseAPIUrl + '/bookmarkservice');

export const bookmarksApi = createApi({
  reducerPath: 'bookmarksApi',
  baseQuery: fetchBaseQuery(),
  tagTypes: ['bookmarks'],
  endpoints: (builder) => ({
    getBookmarksFromCollection: builder.query<GetBookmarksResponse, GetBookmarksRequest>({
      queryFn: async (body, { getState }) => {
        const state = getState() as RootState;
        const token: string = state.authentication.accessToken || '';

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const response = await client.getBookmarksFromCollection(body, token, subscriptionKey);
          return { data: response };
        } catch (error: any) {
          return {
            error: {
              status: error.response?.status || 500,
              data: error.response?.data || 'Unknown error occured',
            },
          };
        }
      },
    }),
    removeBookmarksFromCollection: builder.mutation<any, any>({
      queryFn: async (body, { getState }) => {
        const state = getState() as RootState;
        const token: string = state.authentication.accessToken || '';

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const response = await client.removeBookmarksFromCollection(body, token, subscriptionKey);
          return { data: response };
        } catch (error: any) {
          return {
            error: {
              status: error.response?.status || 500,
              data: error.response?.data || 'Unknown error occured',
            },
          };
        }
      },
      invalidatesTags: ['bookmarks'],
    }),
    createBookmarkInCollection: builder.mutation<any, any>({
      queryFn: async (body, { getState }) => {
        const state = getState() as RootState;
        const token: string = state.authentication.accessToken || '';

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        console.log('Token', token);
        console.log('Body', body);
        try {
          const createBookmarkResponse = await client.createBookmark(body, token, subscriptionKey);
          return { data: createBookmarkResponse };
        } catch (error: any) {
          console.log('Error creating a bookmark', error);
          return {
            error: { status: error?.response?.status || 500, data: error?.response?.data || 'Unknown error occurred' },
          };
        }
      },
      invalidatesTags: ['bookmarks'],
    }),
    checkBookmarkStatus: builder.query<BookmarkResponseInfo, GetBookmarksRequest>({
      queryFn: async (body, { getState }) => {
        const state = getState() as RootState;
        const token: string = state.authentication.accessToken || '';

        console.log('Checkbookmark service called');
        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const response = await client.checkBookmarkStatus(body.searchQuery!, token, subscriptionKey);
          return { data: response };
        } catch (error: any) {
          return {
            error: {
              status: error.response?.status || 500,
              data: error.response?.data || 'Unknown error occured',
            },
          };
        }
      },
      providesTags: ['bookmarks'],
    }),
  }),
});

export const {
  useGetBookmarksFromCollectionQuery,
  useRemoveBookmarksFromCollectionMutation,
  useCreateBookmarkInCollectionMutation,
  useCheckBookmarkStatusQuery,
} = bookmarksApi;
