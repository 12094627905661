import { useGetPastEventsQuery } from '@/services/apiWeb/searchServices/pastEvents';
import { useGetUpcomingEventsQuery } from '@/services/apiWeb/searchServices/upcomingEvents';
import EventsSkeletons from '@/skeletons/eventsSkeletons';
import { formatEventDateRange } from '@/utils/helpers';
import React, { useState } from 'react';

const Events: React.FC = () => {
  const [activeTab, setActiveTab] = useState('upcoming'); // State to track active tab

  const { data: upcomingEvents, isFetching: isFetchingUpcoming } =
    useGetUpcomingEventsQuery(
      {
        pageNumber: 1,
        pageSize: 2,
      },
      {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
      },
    );

  const { data: pastEvents, isFetching: isFetchingPast } =
    useGetPastEventsQuery(
      {
        pageNumber: 1,
        pageSize: 2,
      },
      {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
      },
    );

  const isFetching = isFetchingUpcoming || isFetchingPast;

  if (isFetching) {
    return <EventsSkeletons />;
  }

  const getEventLabel = (eventSponsored: any) => {
    return eventSponsored ? 'GRDC Sponsored Event' : 'GRDC Event';
  };

  if (
    (!upcomingEvents || upcomingEvents?.totalCount === 0) &&
    (!pastEvents || pastEvents?.totalCount === 0)
  ) {
    return null;
  }

  // Events displayed on small screens based on the active tab
  const displayedEvents =
    activeTab === 'upcoming' && upcomingEvents
      ? upcomingEvents
      : activeTab === 'past' && pastEvents
        ? pastEvents
        : null;

  return (
    <div className="relative bg-primaryTeal-100 min-h-full overflow-hidden">
      {/* Curvy Background */}
      <div className="absolute inset-0 w-full bg-primaryTeal-200 rounded-r-[200px] -left-1/4 top-0 h-full"></div>

      {/* Main Container */}
      <div className="container relative mx-auto px-4 mb-12">
        {/* Header Section */}
        <div className="flex flex-col sm:flex-row justify-start sm:justify-between mt-8 sm:mt-12 lg:mt-16">
          <h2 className="text-3xl sm:text-4xl font-bold text-neutral-900 text-left sm:flex-grow">
            Events
          </h2>
          {/* Button for larger screens */}
          <button
            className="hidden sm:inline-block sm:ml-4 px-4 sm:px-6 py-2 sm:py-3 bg-primary-400 text-white text-lg font-bold rounded-md hover:bg-primary-500 transition-all"
            onClick={() =>
              window.open(
                'https://grdc.com.au/events/list',
                '_blank',
                'noopener,noreferrer',
              )
            }
          >
            See all events
          </button>
        </div>

        {/* Event Section for Large Screens */}
        <div className="hidden sm:block mt-8 sm:mt-12">
          {/* Upcoming Events */}
          {upcomingEvents && (
            <>
              <h3 className="text-2xl font-bold text-neutral-900 mb-6">
                Upcoming
              </h3>
              <div className="grid gap-6 sm:grid-cols-2">
                {upcomingEvents?.items?.map((event) => (
                  <div
                    key={event.document?.id}
                    className="relative p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow flex flex-col"
                  >
                    <div className="absolute inset-x-0 top-0 bg-tint-light rounded-t-md text-lg text-white font-regular px-4 py-2 text-left">
                      {formatEventDateRange(
                        event.document?.eventStartDate,
                        event.document?.eventEndDate,
                      )}
                    </div>

                    <div className="flex-grow pt-12">
                      <div className="bg-semanticsSuccess-100 text-sm font-semibold px-2 py-1 rounded-md inline-block mb-2">
                        {event.document?.eventType}
                      </div>
                      <h5 className="text-xl font-bold text-neutral-800 mb-2">
                        <a
                          href={event.document?.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hover:underline"
                        >
                          {event.document?.title}
                        </a>
                      </h5>
                    </div>

                    <div className="text-sm text-neutral-600 mt-2">
                      {/* Display name if present */}
                      {event.document?.location?.name
                        ? event.document.location.name
                        : ''}

                      {/* Display state if present, with a comma if name is also present */}
                      {event.document?.location?.state?.length
                        ? `${
                            event.document.location.name ? ', ' : ''
                          }${event.document.location.state.join(', ')}`
                        : ''}

                      {/* Display region if present, with a separator if name or state is present */}
                      {event.document?.region?.length
                        ? `${
                            event.document.location?.name ||
                            event.document.location?.state?.length
                              ? ' | '
                              : ''
                          }${event.document.region.join(', ')}`
                        : ''}
                    </div>

                    <div className="text-sm text-neutral-600 mt-1 flex items-center">
                      <img
                        src="/MyGRDC.png"
                        alt="Type Icon"
                        className="w-4 h-4 mr-2 grayscale"
                      />
                      {getEventLabel(event.document?.eventSponsored)}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {/* Past Events */}
          {pastEvents && (
            <div className="mt-12">
              <h3 className="text-2xl font-bold text-neutral-900 mb-6">Past</h3>

              <div className="grid gap-6 sm:grid-cols-2">
                {pastEvents?.items?.map((event) => (
                  <div
                    key={event.document?.id}
                    className="relative p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow flex flex-col"
                  >
                    <div className="absolute inset-x-0 top-0 bg-secondaryBlue-300 rounded-t-md text-lg text-white font-regular px-4 py-2 text-left">
                      {formatEventDateRange(
                        event.document?.eventStartDate,
                        event.document?.eventEndDate,
                      )}
                    </div>

                    <div className="flex-grow pt-12">
                      <div className="bg-secondaryBlue-100 text-sm font-semibold px-2 py-1 rounded-md inline-block mb-2">
                        {event.document?.eventType}
                      </div>
                      <h5 className="text-xl font-bold text-neutral-800 mb-2">
                        <a
                          href={event.document?.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hover:underline"
                        >
                          {event.document?.title}
                        </a>
                      </h5>
                    </div>

                    <div className="text-sm text-neutral-600 mt-2">
                      {/* Display name if present */}
                      {event.document?.location?.name
                        ? event.document.location.name
                        : ''}

                      {/* Display state if present, with a comma if name is also present */}
                      {event.document?.location?.state?.length
                        ? `${
                            event.document.location.name ? ', ' : ''
                          }${event.document.location.state.join(', ')}`
                        : ''}

                      {/* Display region if present, with a separator if name or state is present */}
                      {event.document?.region?.length
                        ? `${
                            event.document.location?.name ||
                            event.document.location?.state?.length
                              ? ' | '
                              : ''
                          }${event.document.region.join(', ')}`
                        : ''}
                    </div>
                    <div className="text-sm text-neutral-600 mt-1 flex items-center">
                      <img
                        src="/MyGRDC.png"
                        alt="Type Icon"
                        className="w-4 h-4 mr-2 grayscale"
                      />
                      {getEventLabel(event.document?.eventSponsored)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Tab Navigation for Small Screens */}
        <div className="sm:hidden mt-6">
          <div className="flex justify-center border-b-2 border-gray-200">
            <button
              className={`px-4 py-2 font-bold text-neutral-900 ${
                activeTab === 'upcoming'
                  ? 'border-b-4 border-primary-400 text-neutral-900'
                  : 'text-neutral-500'
              }`}
              onClick={() => setActiveTab('upcoming')}
            >
              Upcoming
            </button>
            <button
              className={`px-4 py-2 font-bold text-neutral-900 ${
                activeTab === 'past'
                  ? 'border-b-4 border-primary-400 text-neutral-900'
                  : 'text-neutral-500'
              }`}
              onClick={() => setActiveTab('past')}
            >
              Past
            </button>
          </div>
        </div>

        {/* Event Section for Small Screens Filtered by Tab */}
        <div className="sm:hidden mt-6">
          <div className="grid gap-6">
            {displayedEvents?.items?.map((event) => (
              <div
                key={event.document?.id}
                className="relative p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow"
              >
                <div
                  className={`absolute inset-x-0 top-0 rounded-t-md text-lg text-white font-regular px-4 py-2 text-left ${
                    activeTab === 'upcoming'
                      ? 'bg-tint-light'
                      : 'bg-secondaryBlue-300'
                  }`}
                >
                  {formatEventDateRange(
                    event.document?.eventStartDate,
                    event.document?.eventEndDate,
                  )}
                </div>
                <div className="pt-12">
                  <div
                    className={`text-sm font-semibold px-2 py-1 rounded-md inline-block mb-2 ${
                      activeTab === 'upcoming'
                        ? 'bg-semanticsSuccess-100'
                        : 'bg-secondaryBlue-100'
                    }`}
                  >
                    {event.document?.eventType}
                  </div>
                  <h5 className="text-xl font-bold text-neutral-800 mb-2">
                    <a
                      href={event.document?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline"
                    >
                      {event.document?.title}
                    </a>
                  </h5>
                  <div className="text-sm text-neutral-600 mt-2">
                    {/* Display name if present */}
                    {event.document?.location?.name
                      ? event.document.location.name
                      : ''}

                    {/* Display state if present, with a comma if name is also present */}
                    {event.document?.location?.state?.length
                      ? `${
                          event.document.location.name ? ', ' : ''
                        }${event.document.location.state.join(', ')}`
                      : ''}

                    {/* Display region if present, with a separator if name or state is present */}
                    {event.document?.region?.length
                      ? `${
                          event.document.location?.name ||
                          event.document.location?.state?.length
                            ? ' | '
                            : ''
                        }${event.document.region.join(', ')}`
                      : ''}
                  </div>
                  <div className="text-sm text-neutral-600 mt-1 flex items-center">
                    <img
                      src="/MyGRDC.png"
                      alt="Type Icon"
                      className="w-4 h-4 mr-2 grayscale"
                    />
                    {getEventLabel(event.document?.eventSponsored)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Button for smaller screens - with dynamic text */}
        <div className="flex justify-center sm:hidden mt-8">
          <button className="px-4 py-2 sm:py-3 bg-primary-400 text-white font-bold rounded-md hover:bg-primary-500 transition-all w-full">
            <a
              href={
                activeTab === 'upcoming'
                  ? 'https://grdc.com.au/events/list'
                  : 'https://grdc.com.au/events/past-events'
              }
              target="_blank"
              rel="noopener noreferrer"
              className="px-4 py-2 sm:py-3 bg-primary-400 text-white font-bold rounded-md hover:bg-primary-500 transition-all w-full text-center"
            >
              {activeTab === 'upcoming'
                ? 'See all upcoming events'
                : 'See all past events'}
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Events;
