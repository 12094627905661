import { useGetCropsQuery } from '@common/services/api/profileServices/crops';
import {
  getPersona,
  incrementStep,
  setPersona,
} from '@common/store/registrationSlice';
import BoxSelection from '@components/BoxSelection';
import Button from '@components/button';
import Spinner from '@components/spinner';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Footer from './footer';
import HorizontalBar from './horizontalBar';
import RegistrationSubtitle from './registrationSubtitle';
import RegistrationTitle from './registrationTitle';

interface Data {
  refId: string;
  refText: string;
}

const Crops = () => {
  const dispatch = useDispatch();
  const persona = useSelector(getPersona);
  const currentCrops = persona.crops || [];
  const { data: cropTypes, isLoading } = useGetCropsQuery();
  const [selectedCropTypes, setSelectedCropTypes] = useState<Data[]>([]);
  const [selectedCrops, setSelectedCrops] = useState<Data[]>(currentCrops);

  useEffect(() => {
    dispatch(setPersona({ crops: selectedCrops }));
  }, [dispatch, selectedCrops]);

  const allCropTypes = useMemo(() => {
    const onlyCropTypes = cropTypes?.map((cropType) => ({
      refId: cropType.refId,
      refText: cropType.refText,
    }));
    return onlyCropTypes;
  }, [cropTypes]);

  const handleCrops = (clickedItem: Data) => {
    const exists = currentCrops.some(
      (crop) => crop.refId === clickedItem.refId,
    );

    let updatedCrops;
    if (exists) {
      updatedCrops = currentCrops.filter(
        (crop) => crop.refId !== clickedItem.refId,
      );
    } else {
      updatedCrops = [...currentCrops, clickedItem];
    }

    setSelectedCrops(updatedCrops);
  };

  if (!cropTypes || !allCropTypes || isLoading) {
    return <Spinner />;
  }

  const cropsCount = persona.crops?.length ?? 0;

  const displayClearBtn = persona.crops && persona.crops?.length !== 0;

  const displayActiveNextBtn =
    persona.crops?.length !== undefined && persona.crops?.length !== 0;

  return (
    <div className="mt-6 mb-32">
      <RegistrationTitle title="What crops are you interested in?" />
      <RegistrationSubtitle subtitle="Select at least one crop to personalise your MyGRDC experience. You can always change this later." />
      <div className="my-4">
        <HorizontalBar
          items={allCropTypes.map((cropType) => ({
            refId: cropType.refId ?? 'Unknown',
            refText: cropType.refText ?? 'Unknown',
          }))}
          onSelectionChange={(selectedItems) =>
            setSelectedCropTypes(selectedItems)
          }
        />

        {cropTypes
          .filter((cropType) => {
            const selectedCropsIds = selectedCropTypes.map(
              (selectedCropType) => selectedCropType.refId,
            );
            return selectedCropsIds.includes(cropType.refId ?? '');
          })
          .map((cropType) => (
            <BoxSelection
              key={cropType.refId ?? 'Unknown'}
              title={cropType.refText ?? 'Unknown'}
              items={(cropType.crops || []).map((crop) => ({
                refId: crop.refId ?? 'Unknown',
                refText: crop.refText ?? 'Unknown',
              }))}
              currentSelections={currentCrops}
              onSelectionChange={handleCrops}
            />
          ))}
      </div>
      <Footer>
        <div className="flex justify-end h-full">
          <div className="flex items-center w-full gap-5 ">
            <div className="flex-1 text-xl font-semibold">
              {`${cropsCount} crop${cropsCount === 1 ? '' : 's'} selected`}
            </div>
            {displayClearBtn ? (
              <Button
                title="Clear"
                type="outline"
                onClick={() => dispatch(setPersona({ crops: undefined }))}
              />
            ) : (
              <Button title="Clear" type="disabled" />
            )}
            {displayActiveNextBtn ? (
              <Button
                title="Next"
                type="filled"
                onClick={() => dispatch(incrementStep())}
              />
            ) : (
              <Button title="Next" type="disabled" />
            )}
          </div>
        </div>
      </Footer>
    </div>
  );
};
export default Crops;
