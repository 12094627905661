import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getAccessToken } from '@/auth';
import COMMON_PROPERTIES from '@common/properties/common-properties';
import {
  BookmarkServiceClient,
  GetBookmarksRequest,
  GetBookmarksResponse,
  RemoveBookmarksPayload,
  RemoveBookmarksResponseInfo,
} from '@common/services/api/bookmarkServices/_bookmarkService';

const baseAPIUrl = COMMON_PROPERTIES.baseAPIUrl;
const subscriptionKey = COMMON_PROPERTIES.subscriptionKey ?? '';
const client = new BookmarkServiceClient(baseAPIUrl + '/bookmarkservice');

export const bookmarksApi = createApi({
  reducerPath: 'bookmarksApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getBookmarksFromCollection: builder.query<
      GetBookmarksResponse,
      GetBookmarksRequest
    >({
      queryFn: async (body) => {
        const token = await getAccessToken();

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const response = await client.getBookmarksFromCollection(
            body,
            token,
            subscriptionKey,
          );
          return { data: response };
        } catch (error: any) {
          return {
            error: {
              status: error.response?.status || 500,
              data: error.response?.data || 'Unknown error occured',
            },
          };
        }
      },
    }),

    removeBookmarksFromCollection: builder.mutation<
      RemoveBookmarksResponseInfo,
      RemoveBookmarksPayload
    >({
      queryFn: async (body) => {
        const token = await getAccessToken();

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const response = await client.removeBookmarksFromCollection(
            body,
            token,
            subscriptionKey,
          );
          return { data: response };
        } catch (error: any) {
          return {
            error: {
              status: error.response?.status || 500,
              data: error.response?.data || 'Unknown error occured',
            },
          };
        }
      },
    }),
  }),
});

export const {
  useGetBookmarksFromCollectionQuery,
  useRemoveBookmarksFromCollectionMutation,
} = bookmarksApi;
