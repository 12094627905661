import { useGetCropsInfoQuery } from '@/services/apiWeb/profileServices/crops';
import { useGetTopicsInfoQuery } from '@/services/apiWeb/profileServices/otherTopics';
import { useGetUserProfileQuery } from '@/services/apiWeb/profileServices/userProfile';
import { useGetUserTypeInfoQuery } from '@/services/apiWeb/profileServices/userTypes';
import { useGetRegionsInfoQuery } from '@common/services/api/profileServices/regions';
import { UserProfileInfo } from '@common/services/api/profileServices/_profileService';
import Spinner from '@components/spinner';
import { useNavigate } from 'react-router-dom';
import chevronLeft from '../../common/assets/images/mygrdc/chevron-left.svg';
import TruncatedText from './dynamicTruncatedText';
interface PreferencesProps {
  userProfile?: UserProfileInfo;
}

const Preferences = () => {
  const navigate = useNavigate();
  const { data: userProfile } = useGetUserProfileQuery();

  const { data: userType } = useGetUserTypeInfoQuery({
    refIds: userProfile?.userType ? [userProfile.userType] : [],
  });

  const { data: cropsData, isLoading: cropsLoading } = useGetCropsInfoQuery({
    refIds: userProfile?.crops ? userProfile.crops : [],
  });

  const { data: topicsData, isLoading: topicsLoading } = useGetTopicsInfoQuery({
    refIds: userProfile?.topics ? userProfile.topics : [],
  });

  const { data: regionData } = useGetRegionsInfoQuery({
    refIds: userProfile?.primaryRegion ? [userProfile.primaryRegion] : [],
  });

  const { data: additionalRegionData } = useGetRegionsInfoQuery({
    refIds: userProfile?.primarySubregion ? [userProfile.primarySubregion] : [],
  });

  return cropsLoading || topicsLoading ? (
    <Spinner />
  ) : (
    <div id="preferences-page">
      <button
        id="back-to-profile-button"
        className=" mb-6 flex items-center"
        onClick={() => navigate('/profileSummary')}
      >
        <img
          id="back-chevron-icon"
          src={chevronLeft}
          alt="Chevron Right"
          className="w-5 h-5"
        />
        <p
          id="back-to-profile-text"
          className="text-primary-500 font-bold ml-2"
        >
          Back to profile
        </p>
      </button>

      <h1 id="preferences-detail-title" className="text-2xl font-bold mb-6">
        Preferences and interests
      </h1>
      <div className="space-y-6 max-w-[70rem]">
        {
          // TODO: Uncomment this code when user type part comes
          /* <div className="flex justify-between items-center border-b pb-4 ">
          <div>
            <h2 className="font-semiBold">User type</h2>
            <p className="font-regular text-neutral-600 mt-2">
              {userType?.items?.map((item) => item.refText).join(', ') || ''}
            </p>
          </div>
          <button className="border-2 border-primary-400 text-primary-400 font-bold rounded-lg px-4 py-2">
            Edit
          </button>
        </div> */
        }

        <div className="flex justify-between items-center border-b">
          <div>
            <h2 className="font-semiBold">Crops</h2>

            <TruncatedText
              items={cropsData?.items || []}
              className="font-regular text-neutral-600 mt-2"
            />
          </div>
          <button
            className="border-2 border-primary-400 text-primary-400 font-bold rounded-lg px-4 py-2"
            onClick={() =>
              navigate('/profileSummary/crops', {
                state: { cropsData: userProfile?.crops || [] },
              })
            }
          >
            Edit
          </button>
        </div>

        <div className="flex justify-between items-center border-b">
          <div>
            <h2 className="font-semiBold">Topics</h2>
            <TruncatedText
              items={topicsData?.items || []}
              className="font-regular text-neutral-600 mt-2"
            />
          </div>
          <button
            className="border-2 border-primary-400 text-primary-400 font-bold rounded-lg px-4 py-2"
            onClick={() =>
              navigate('/profileSummary/topics', {
                state: { topicsData: userProfile?.topics || [] },
              })
            }
          >
            Edit
          </button>
        </div>

        {
          //TODO: uncomment this code when primary region part comes
          /* <div className="flex justify-between items-center border-b">
          <div>
            <h2 className="font-semiBold">Primary Region</h2>
            <TruncatedText
              items={regionData?.items || []}
              className="font-regular text-neutral-600 mt-2"
            />
          </div>
          <button className="border-2 border-primary-400 text-primary-400 font-bold rounded-lg px-4 py-2">
            Edit
          </button>
        </div> */
        }

        {
          //TODO: uncomment this code when additional sub-regions part comes
          /* <div className="flex justify-between items-center border-b">
          <div>
            <h2 className="font-semiBold">Additiona sub-regions</h2>
            <TruncatedText
              items={additionalRegionData?.items || []}
              className="font-regular text-neutral-600 mt-2"
            />
          </div>
          <button className="border-2 border-primary-400 text-primary-400 font-bold rounded-lg px-4 py-2">
            Edit
          </button>
        </div> */
        }
      </div>
    </div>
  );
};
export default Preferences;
