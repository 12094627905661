import { useGetUserProfileQuery } from '@/services/apiWeb/profileServices/userProfile';
import { useGetUserTypeInfoQuery } from '@/services/apiWeb/profileServices/userTypes';
import Spinner from '@components/spinner';
import { useNavigate } from 'react-router-dom';
import chevronRightGreen from '../../common/assets/images/mygrdc/chevron-right-green.svg';

const MainContent = () => {
  const navigate = useNavigate();

  const { data: userProfile } = useGetUserProfileQuery();
  const { data: userType, isLoading: userProfileLoading } =
    useGetUserTypeInfoQuery({
      refIds: userProfile?.userType ? [userProfile.userType] : [],
    });

  return (
    <>
      {userProfileLoading ? (
        <Spinner />
      ) : (
        <div id="user-profile-container">
          <h1 id="user-profile-greeting" className="text-4xl font-bold">
            Hi, {userProfile?.nameDetails?.displayName}
          </h1>
          <h5 id="user-profile-type" className="text-xl font-bold pt-4 mb-10">
            {userType?.items?.map((item) => item.refText).join(', ') ?? ''}
          </h5>
          <div id="user-profile-sections" className="space-y-4 max-w-6xl">
            <div
              id="preferences-section"
              className="w-full text-left flex justify-between items-center border-b pb-4 cursor-pointer"
              onClick={() => navigate('/profileSummary/preferences')}
            >
              <div id="preferences-content">
                <h2
                  id="preferences-title"
                  className="text-lg font-semiBold text-neutral-800"
                >
                  Preferences and interests
                </h2>
                <p
                  id="preferences-description"
                  className="font-regular text-neutral-600"
                >
                  Change your topics, crops, user type, and regions.
                </p>
              </div>
              <img
                id="preferences-chevron"
                src={chevronRightGreen}
                alt="Chevron Right"
                className="w-8 h-8"
              />
            </div>

            <div
              id="personal-details-section"
              className="w-full text-left flex justify-between items-center border-b pb-4 cursor-pointer"
              onClick={() => navigate('/profileSummary/personalDetails')}
            >
              <div id="personal-details-content">
                <h2
                  id="personal-details-title"
                  className="text-lg font-semiBold text-neutral-800"
                >
                  Personal details
                </h2>
                <p
                  id="personal-details-description"
                  className="font-regular text-neutral-600"
                >
                  Edit your name
                </p>
              </div>
              <img
                id="personal-details-chevron"
                src={chevronRightGreen}
                alt="Chevron Right"
                className="w-8 h-8"
              />
            </div>

            <div
              id="deactivate-section"
              className="w-full text-left flex justify-between items-center border-b pb-4 cursor-pointer"
              onClick={() => navigate('/profileSummary/deactivateAccount')}
            >
              <div id="deactivate-content">
                <h2
                  id="deactivate-title"
                  className="text-lg font-semiBold text-neutral-800"
                >
                  Account
                </h2>
                <p
                  id="deactivate-description"
                  className="font-regular text-neutral-600"
                >
                  Deactivate your account
                </p>
              </div>
              <img
                id="deactivate-chevron"
                src={chevronRightGreen}
                alt="Chevron Right"
                className="w-8 h-8"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MainContent;
