import {
  useGetUserProfileQuery,
  useSaveUserProfileMutation,
} from '@/services/apiWeb/profileServices/userProfile';
import Spinner from '@components/spinner';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import chevronLeft from '../../common/assets/images/mygrdc/chevron-left.svg';

const PersonalDetails = () => {
  const { data: userProfile } = useGetUserProfileQuery();
  const navigate = useNavigate();

  const [saveUserProfile] = useSaveUserProfileMutation();

  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [firstName, setFirstName] = useState(
    userProfile?.nameDetails?.firstName,
  );
  const [lastName, setLastName] = useState(userProfile?.nameDetails?.lastName);
  const [displayName, setDisplayName] = useState(
    userProfile?.nameDetails?.displayName,
  );

  useEffect(() => {
    const hasChanged =
      firstName !== userProfile?.nameDetails?.firstName ||
      lastName !== userProfile?.nameDetails?.lastName ||
      displayName !== userProfile?.nameDetails?.displayName;

    const isValid =
      (firstName?.trim()?.length ?? 0) > 0 &&
      (firstName?.length ?? 0) <= 64 &&
      (lastName?.trim()?.length ?? 0) > 0 &&
      (lastName?.length ?? 0) <= 64 &&
      (displayName?.trim()?.length ?? 0) > 0 &&
      (displayName?.length ?? 0) <= 256;

    setIsChanged(hasChanged && isValid);
  }, [firstName, lastName, displayName, userProfile]);

  const handleSaveChanges = async () => {
    if (
      firstName === userProfile?.nameDetails?.firstName &&
      lastName === userProfile?.nameDetails?.lastName &&
      displayName === userProfile?.nameDetails?.displayName
    ) {
      return;
    }

    const userDetailsPayload = {
      nameDetails: { firstName, lastName, displayName },
    };

    setIsLoading(true);

    try {
      await saveUserProfile(userDetailsPayload);
    } catch (error) {
      console.error('Error saving profile:', error);
    } finally {
      setIsChanged(false);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div id="personal-details-page">
      <button
        id="back-to-profile-button"
        className=" mb-6 flex items-center"
        onClick={() => navigate('/profileSummary')}
      >
        <img
          id="back-chevron-icon"
          src={chevronLeft}
          alt="Chevron Right"
          className="w-5 h-5"
        />
        <p
          id="back-to-profile-text"
          className="text-primary-500 font-bold ml-2"
        >
          Back to profile
        </p>
      </button>

      <h1 id="personal-detail-title" className="text-2xl font-bold mb-6 mr-4">
        Personal details
      </h1>
      <div id="personal-details-form" className="space-y-6">
        <div id="first-name-field">
          <label id="first-name-label" className="block font-bold mb-2">
            First Name
          </label>
          <input
            id="first-name-input"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="block w-full border border-shades-blackBorder rounded-md p-2 shadow-inner max-w-6xl h-14 font-regular text-neutral-800"
          />
        </div>
        <div id="last-name-field">
          <label id="last-name-label" className="block font-bold mb-2">
            Last Name
          </label>
          <input
            id="last-name-input"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="block w-full border border-shades-blackBorder rounded-md p-2 shadow-inner max-w-6xl h-14 font-regular text-neutral-800"
          />
        </div>
        <div id="display-name-field">
          <label id="display-name-label" className="block font-bold mb-2">
            Display Name
          </label>
          <input
            id="display-name-input"
            type="text"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            className="block w-full border border-shades-blackBorder rounded-md p-2 shadow-inner max-w-6xl h-14 font-regular text-neutral-800"
          />
          <p
            id="display-name-description"
            className="font-regular text-neutral-800 mt-4  max-w-6xl"
          >
            This name is displayed throughout MyGRDC and may be visible to
            others. You can use your real name, a nickname, or something more
            anonymous.
          </p>
        </div>
        <div
          id="save-changes-section"
          className="flex justify-end max-w-6xl pt-2"
        >
          <button
            id="save-changes-button"
            type="submit"
            onClick={handleSaveChanges}
            disabled={!isChanged}
            className={`font-bold py-3 px-8 rounded-lg ${
              !isChanged
                ? 'bg-neutral-200 text-neutral-500'
                : 'bg-primary-400 text-white'
            }`}
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
