import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import chevron_left from '@common/assets/images/mygrdc/chevron-left.svg';
import { useAppSelector } from '@common/hooks/useReduxStore';
import { selectAccessToken, setToken } from '@common/store/authenticationSlice';
import {
  decrementStep,
  selectCurrentStep,
} from '@common/store/registrationSlice';
import Container from '@components/container';
import ProgressBar from '@components/progressBar';
import AccountCreation from './accountCreation';
import AdditionalSubRegions from './additionalSubRegions';
import Crops from './crops';
import DescribeYourselfStep from './describeYourself';
import OtherTopics from './otherTopics';
import PersonalisingFeed from './personalisingFeed';
import PostCode from './postCode';
import RegistrationContainer from './registrationContainer';

import { useGetUserProfileQuery } from '@common/services/api/profileServices/userProfile';
import { pca } from '../../index';
import { loginRequest } from '../../msal.config';

const TOTAL_STEPS_FOR_PROGRESSBAR = 5;

const Registration = () => {
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentStep = useSelector(selectCurrentStep);
  const token = useAppSelector(selectAccessToken);
  const { data: userProfile, isLoading } = useGetUserProfileQuery(undefined, {
    skip: !token,
  });

  const account = accounts.length > 0 ? accounts[0] : undefined;

  useEffect(() => {
    // Fetch token if not already available
    const fetchToken = async () => {
      if (account) {
        try {
          const tokenResponse = await pca.acquireTokenSilent({
            ...loginRequest,
            account: account,
          });

          if (tokenResponse?.accessToken) {
            dispatch(
              setToken({
                token: tokenResponse.accessToken,
                tokenExpires: tokenResponse.expiresOn?.toISOString() ?? null,
                objectId: account.homeAccountId,
              }),
            );
          }
        } catch (error) {
          console.error('Token acquisition failed:', error);
        }
      }
    };

    fetchToken();
  }, [account, dispatch]);

  useEffect(() => {
    if (userProfile) {
      // Ensure user goes through steps sequentially
      if (userProfile?.isProfileProcessFinished) {
        navigate('/');
      }
    }
  }, [userProfile, currentStep, navigate, dispatch]);

  const displayChevron = currentStep > 1 && currentStep < 6;
  const displayProgressBar =
    currentStep > 0 && currentStep <= TOTAL_STEPS_FOR_PROGRESSBAR;

  return (
    <Container>
      <RegistrationContainer className="my-20 ">
        {displayChevron && <LeftChevron />}
        {displayProgressBar && (
          <ProgressBar
            totalSteps={TOTAL_STEPS_FOR_PROGRESSBAR}
            currentStep={currentStep}
          />
        )}

        {currentStep === 0 && <AccountCreation />}
        {currentStep === 1 && <DescribeYourselfStep />}
        {currentStep === 2 && <PostCode />}
        {currentStep === 3 && <AdditionalSubRegions />}
        {currentStep === 4 && <Crops />}
        {currentStep === 5 && <OtherTopics />}
        {/* {currentStep === 6 && <NotificationPermission />} */}
        {currentStep === 6 && <PersonalisingFeed />}
      </RegistrationContainer>
    </Container>
  );
};
export default Registration;

const LeftChevron = () => {
  const dispatch = useDispatch();
  return (
    <div className="h-8 mb-2">
      <img
        src={chevron_left}
        alt="Chevron Left"
        width={24}
        onClick={() => dispatch(decrementStep())}
        className="cursor-pointer"
      />
    </div>
  );
};
