import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import Spinner from './components/spinner';

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { instance } = useMsal();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await instance.initialize();
        await instance.handleRedirectPromise();

        const accounts = instance.getAllAccounts();

        if (accounts.length > 0) {
          instance.setActiveAccount(accounts[0]);
        } else {
          try {
            await instance.ssoSilent({
              scopes: ['openid', 'profile', 'email'],
            });
          } catch (ssoError) {
            console.error('SSO Silent failed:', ssoError);
          }
        }
      } catch (error) {
        console.error('Auth check failed:', error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, [instance]);

  if (loading) return <Spinner />;

  return <>{children}</>;
};

export default AuthProvider;
