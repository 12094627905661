import React from 'react';
import {
  formatDatePublished,
  getFormattedUrl,
  joinRegions,
} from '@/utils/helpers';
import { SearchResult_contentItem } from '@common/services/api/searchServices/_contentSearchService';

interface ResultsListProps {
  items: SearchResult_contentItem[];
  icon?: string; // Optional icon for items (e.g. podcast icon)
}

const ResultsList: React.FC<ResultsListProps> = ({ items, icon }) => (
  <div id="result-list" className="space-y-6 mt-10">
    {items.map((data, index) => (
      <div
        key={data?.document?.id || index}
        className={`pb-4 flex ${
          data?.document?.images?.thumbnail ? 'items-start space-x-4' : ''
        } ${index !== items.length - 1 ? 'border-b' : ''}`}
      >
        {/* Thumbnail Section */}
        {data?.document?.images?.thumbnail && (
          <div className="relative w-20 h-20 flex-shrink-0 sm:w-20 sm:h-20">
            <img
              src={data.document.images.thumbnail}
              alt={data.document.title}
              className="w-full h-full object-cover rounded-md"
            />
            {icon && (
              <img
                src={icon}
                alt="Icon"
                className="absolute bottom-2 left-2 h-7 w-7 rounded-full"
              />
            )}
          </div>
        )}

        {/* Content Section */}
        <div
          className={`${
            data?.document?.images?.thumbnail ? 'flex-1 w-36' : ''
          }`}
        >
          {/* Date and Region */}
          <p className="text-sm font-regular truncate">
            {data?.document?.datePublished
              ? formatDatePublished(data.document.datePublished)
              : ''}
            {data?.document?.datePublished &&
            Array.isArray(data?.document?.region) &&
            data?.document?.region.length > 0
              ? ' | '
              : ''}
            {data?.document?.region ? joinRegions(data.document.region) : ''}
          </p>

          {/* Title */}
          <h3
            className={`${
              data?.document?.images?.thumbnail
                ? 'text-lg font-semiBold mt-1'
                : 'font-bold text-lg mt-2'
            } overflow-hidden text-ellipsis line-clamp-4`}
          >
            <a
              href={
                getFormattedUrl(data?.document?.url, data?.document?.type) ??
                '#'
              }
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              {data.document?.title}
            </a>
          </h3>

          {/* Summary */}
          <p className="font-regular text-neutral-600 overflow-hidden text-ellipsis line-clamp-2">
            {data?.document?.summary}
          </p>
        </div>
      </div>
    ))}
  </div>
);

export default ResultsList;
