import { Navigate, Outlet } from 'react-router-dom';
import { useGetUserProfileQuery } from '@/services/apiWeb/profileServices/userProfile';

const ProtectedRoute: React.FC = () => {
  const { data: userProfile } = useGetUserProfileQuery();

  return userProfile?.isProfileProcessFinished ? (
    <Outlet />
  ) : (
    <Navigate to="/registration" replace />
  );
};

export default ProtectedRoute;
