import { useEffect, useRef, useState } from 'react';
import bookmarkIcon from '../../common/assets/images/mygrdc/bookmark-green.svg';
import bookmarkRemoveIconGreen from '../../common/assets/images/mygrdc/bookmark-remove-outline-green.svg';
import checkFilledIcon from '../../common/assets/images/mygrdc/check-filled.svg';
import closeIcon from '../../common/assets/images/mygrdc/close-outline-light-black.svg';
import copyLinkIcon from '../../common/assets/images/mygrdc/copy.svg';
import moreIcon from '../../common/assets/images/mygrdc/more.svg';
import shareIconGreen from '../../common/assets/images/mygrdc/share-bookmark-green.svg';
import Container from '../../components/container';
import NavbarWithMenu from '../../components/navbarWithMenu';
import Footer from './footer';

import { formatDateRegion, getFormattedUrl } from '@/utils/helpers';
import Spinner from '@components/spinner';
import podcastIcon from '../../common/assets/images/mygrdc/podcast.svg';

import {
  useGetBookmarksFromCollectionQuery,
  useRemoveBookmarksFromCollectionMutation,
} from '@/services/apiWeb/bookmarkServices/bookmarks';
import { GetBookmarksResponse } from '@common/services/api/bookmarkServices/_bookmarkService';
import CustomModal from '@components/customModal';

const Saved = () => {
  const requestPayload = {
    sortBy: 0,
  };

  const {
    data: bookmarksCollection,
    isLoading,
    refetch,
    isUninitialized,
  } = useGetBookmarksFromCollectionQuery(requestPayload);

  useEffect(() => {
    if (!isUninitialized) {
      refetch();
    }
  }, [isUninitialized]);

  return (
    <div className="flex flex-col min-h-screen">
      <Container navbar={<NavbarWithMenu />}>
        <div className="w-full bg-primary-500 py-12 text-center h-40">
          <div className="font-bold text-[28px] sm:text-[36px] md:text-[44px] leading-[36px] sm:leading-[46px] md:leading-[53.64px] text-white max-w-full sm:max-w-[600px] tracking-wide text-left pl-28">
            Saved content
          </div>
        </div>
        <div className="flex-1">
          {isLoading ? (
            <Spinner />
          ) : (bookmarksCollection?.items?.length ?? 0) === 0 ? (
            <NoSavedContent />
          ) : (
            <SavedList
              bookmarksCollectionData={bookmarksCollection}
              refetch={refetch}
            />
          )}
        </div>
        <Footer />
      </Container>
    </div>
  );
};

export default Saved;

const NoSavedContent = () => {
  return (
    <div className="flex flex-col items-center justify-center flex-1">
      <div className="flex items-center justify-center w-32 h-32 mt-20 bg-primaryTeal-100 rounded-full">
        <img
          src={bookmarkIcon}
          alt={'bookmark'}
          className="w-16 h-16 object-cover rounded-md"
        />
      </div>
      <h2 className="text-lg font-bold text-gray-800 mt-6">
        No saved content yet
      </h2>
      <p className="font-regular mt-6 w-96 text-center">
        When you are viewing content within MyGRDC, tap on the save button and
        the content will be saved here.
      </p>
    </div>
  );
};

const SavedList = ({
  bookmarksCollectionData,
  refetch,
}: {
  bookmarksCollectionData: GetBookmarksResponse | undefined;
  refetch: () => void;
}) => {
  const [showOptions, setShowOptions] = useState<string | null>(null);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [currentItemUrl, setCurrentItemUrl] = useState<string | null>(null);
  const [currentItemTitle, setCurrentItemTitle] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const [removeBookmark] = useRemoveBookmarksFromCollectionMutation();

  const handleShareClick = (itemUrl: string, itemTitle: string) => {
    setCurrentItemUrl(itemUrl);
    setCurrentItemTitle(itemTitle);
    setShowShareDialog(true);
  };

  const handleRemoveClick = (itemId: string) => {
    setSelectedItem(itemId);
    setIsModalOpen(true);
  };

  const confirmRemove = async () => {
    if (selectedItem) {
      setIsModalOpen(false);
      setIsRefetching(true);

      try {
        await removeBookmark({
          bookmarkIds: [selectedItem],
        });

        refetch();
        setShowToast(true);
      } catch (error) {
        console.error('Error removing bookmark:', error);
      } finally {
        setIsRefetching(false);
        setSelectedItem(null);
      }
    }
  };

  return (
    <div
      id="saved-list-container"
      className="flex flex-col p-6 text-left pl-8 sm:pl-28"
    >
      {isRefetching && (
        <div className="flex justify-center items-center min-h-[300px]">
          <Spinner />
        </div>
      )}

      <div id="saved-items-list">
        {!isRefetching &&
          (bookmarksCollectionData?.items ?? []).map((item) => {
            const itemId = item.id ?? '';
            return (
              <div
                key={itemId}
                className="flex items-center border-b py-4 sm:mr-24"
              >
                {item.thumbnail && (
                  <div
                    id={`thumbnail-${itemId}`}
                    className="relative w-20 h-20 flex-shrink-0 sm:w-20 sm:h-20"
                  >
                    <img
                      src={item.thumbnail}
                      alt={item.title}
                      className="w-16 h-16 object-cover rounded-md"
                    />
                    {item.type == 'podcast' && (
                      <img
                        src={podcastIcon}
                        alt="Icon"
                        className="absolute bottom-4 left-2 h-6 w-6 rounded-full"
                      />
                    )}
                  </div>
                )}

                <div id={`item-details-${itemId}`} className="flex-1">
                  <div
                    id={`item-date-region-${itemId}`}
                    className="text-sm font-regular truncate"
                  >
                    {formatDateRegion(item?.publishedDate, item?.region)}
                  </div>
                  <div
                    id={`item-title-${itemId}`}
                    className="text-lg font-semiBold mt-1"
                  >
                    <a
                      id={`item-link-${itemId}`}
                      href={getFormattedUrl(item?.url, item?.type) ?? '#'}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline"
                    >
                      {item?.title}
                    </a>
                  </div>
                </div>

                <div id={`item-options-${itemId}`} className="relative">
                  <button
                    id={`more-button-${itemId}`}
                    className="text-gray-500 hover:text-gray-700"
                    onClick={() =>
                      setShowOptions(showOptions === itemId ? null : itemId)
                    }
                  >
                    <img src={moreIcon} alt="More" className="w-6 h-6 mr-4" />
                  </button>
                  {showOptions === itemId && (
                    <MoreOptions
                      onClose={() => setShowOptions(null)}
                      onShare={() =>
                        handleShareClick(item?.url ?? '', item?.title ?? '')
                      }
                      onRemove={() => handleRemoveClick(itemId)}
                    />
                  )}
                </div>
              </div>
            );
          })}
      </div>
      {showShareDialog && (
        <ShareDialog
          itemUrl={currentItemUrl}
          itemTitle={currentItemTitle}
          onClose={() => setShowShareDialog(false)}
        />
      )}

      {/* Custom Modal for Remove Confirmation */}
      <CustomModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Remove content?"
      >
        <div className="border-b h-1"></div>
        <div id="logout-modal-content" className="py-1 mb-1">
          <span className="font-regular text-right text-neutral-800">
            Removing this content will also remove it from any collections.
          </span>
        </div>
        <div
          id="logout-modal-actions"
          className="flex flex-col-reverse sm:flex-row justify-end gap-4 mt-8"
        >
          <button
            id="logout-modal-cancel-button"
            onClick={() => setIsModalOpen(false)}
            className="px-6 py-2 border-2 border-primary-400 text-primary-400 font-bold rounded-lg hover:bg-primary-50"
          >
            Cancel
          </button>
          <button
            id="logout-modal-confirm-button"
            onClick={confirmRemove}
            className="px-6 py-2 bg-primary-400 text-white font-bold rounded-lg hover:bg-primary-500"
          >
            Yes, Remove
          </button>
        </div>
      </CustomModal>

      {/* Toast Notification */}
      {showToast && (
        <Toast
          message="Content has been removed"
          onClose={() => setShowToast(false)}
        />
      )}
    </div>
  );
};

const MoreOptions = ({
  onClose,
  onShare,
  onRemove,
}: {
  onClose: () => void;
  onShare: () => void;
  onRemove: () => void;
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleShareClick = () => {
    onShare();
    onClose();
  };

  const handleRemoveClick = () => {
    onRemove();
    onClose();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      id="more-options-dropdown"
      ref={dropdownRef}
      className="absolute z-10 w-48 bg-white rounded-lg shadow right-0 top-full -mt-3"
      onMouseLeave={onClose}
    >
      <div
        id="more-options-share"
        className="px-4 py-2 cursor-pointer hover:bg-primaryTeal-100 hover:rounded-t-lg text-neutral-800 flex items-center font-regular"
        onClick={handleShareClick}
      >
        <img
          id="more-options-share-icon"
          src={shareIconGreen}
          alt="Share"
          className="w-6 h-6 mr-2"
        />
        <span id="more-options-share-text">Share content</span>
      </div>
      <div
        id="more-options-remove"
        className="px-4 py-2 cursor-pointer hover:bg-primaryTeal-100 hover:rounded-b-lg text-neutral-800 flex items-center font-regular"
        onClick={handleRemoveClick}
      >
        <img
          id="more-options-remove-icon"
          src={bookmarkRemoveIconGreen}
          alt="Remove"
          className="w-6 h-6 mr-2"
        />
        <span id="more-options-remove-text">Remove</span>
      </div>
    </div>
  );
};

const ShareDialog = ({
  itemUrl,
  itemTitle,
  onClose,
}: {
  itemUrl: string | null;
  itemTitle: string | null;
  onClose: () => void;
}) => {
  const [showToast, setShowToast] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleCopyLink = async () => {
    if (itemUrl && itemTitle) {
      try {
        const htmlContent = `<a href="${itemUrl}" target="_blank">${itemUrl}</a>`;
        const plainTextContent = `${itemUrl}`;

        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], { type: 'text/html' }),
            'text/plain': new Blob([plainTextContent], { type: 'text/plain' }),
          }),
        ]);

        setShowToast(true);
        setTimeout(() => setShowToast(false), 3500);
      } catch (error) {
        console.error('Failed to copy link:', error);
      }
    }
  };

  return (
    <div
      id="share-dialog-overlay"
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40"
    >
      <div
        id="share-dialog-box"
        ref={modalRef}
        className="bg-white p-6 rounded-lg shadow-lg sm:w-[600px] w-80 relative"
      >
        <div
          id="share-dialog-header"
          className="flex justify-between items-center mb-4"
        >
          <h3
            id="share-dialog-title"
            className="font-bold text-lg text-neutral-800"
          >
            Share this content
          </h3>
          <button
            id="share-dialog-close-btn"
            className="text-neutral-500"
            onClick={onClose}
          >
            <img src={closeIcon} alt="Close" className="w-6 h-6" />
          </button>
        </div>
        <button
          id="copy-link-btn"
          className="w-full flex items-center justify-center gap-2 bg-primary-50 border-2 border-primary-400 text-primary-400 font-bold py-2 rounded-lg"
          onClick={handleCopyLink}
        >
          <img src={copyLinkIcon} alt="Copy" className="w-6 h-6 mr-2" />
          Copy link
        </button>

        {showToast && (
          <div
            id="share-toast-container"
            className="absolute inset-x-0 top-12 flex justify-center"
          >
            <div
              id="share-toast-message"
              className="bg-shades-blackBorder font-regular text-shades-white text-sm px-4 py-2 rounded shadow flex items-center gap-2"
            >
              <img
                id="share-toast-icon"
                src={checkFilledIcon}
                alt={'checkbox'}
                className="w-6 h-6 object-cover rounded-md"
              />
              <span id="share-toast-text">Link copied!</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Toast = ({
  message,
  onClose,
}: {
  message: string;
  onClose: () => void;
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="fixed bottom-10 right-10 font-regular text-shades-white text-sm py-2 px-4 rounded flex items-center gap-2 bg-shades-blackBorder ">
      <img
        src={checkFilledIcon}
        alt={'checkbox'}
        className="w-6 h-6 object-cover rounded-md"
      />{' '}
      <span>{message}</span>
    </div>
  );
};
