import { useGetUserProfileQuery } from '@/services/apiWeb/profileServices/userProfile';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import chevronLeft from '../../common/assets/images/mygrdc/chevron-left.svg';

const DeactivateAccount = () => {
  const { data: userProfile } = useGetUserProfileQuery();

  const navigate = useNavigate();
  const [emailOpened, setEmailOpened] = useState(false);

  const userEmail = userProfile?.email;
  console.log('user', userProfile);

  const email = 'subscribers@grdc.com.au';
  const subject = encodeURIComponent('GRDC account change request');
  const body = encodeURIComponent(
    `Hi GRDC,\n\n` +
      `I would like to request changes to my GRDC account.This is my preferred option:\n\n` +
      ` (choose 1 option, delete the other)\n\n` +
      `1. Deactivate your MyGRDC account \n` +
      `I understand that I won’t be able to log in to MyGRDC, but some preferences (e.g., crops and topics) will be saved. If I return to MyGRDC in the future, I will need to go through the registration process again. My details will remain in the GRDC database, and I will continue receiving GRDC communications if subscribed.\n\n` +
      `OR\n\n` +
      `2. Delete your entire GRDC account \n` +
      `I understand this will permanently remove my details from the GRDC database including my MyGRDC account and preferences. I understand that I will no longer receive any GRDC communications, including GroundCover magazine.\n\n` +
      `Thank you.`,
  );

  const handleEmailClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const mailtoUrl = `mailto:${email}?cc=${encodeURIComponent(
      userEmail ?? '',
    )}&subject=${subject}&body=${body}`;

    window.open(mailtoUrl, '_blank');
    setEmailOpened(true);
  };

  useEffect(() => {
    const handleFocus = () => {
      if (emailOpened) {
        navigate('/profileSummary/deactivateAccount');
      }
    };

    window.addEventListener('focus', handleFocus);
    return () => window.removeEventListener('focus', handleFocus);
  }, [emailOpened, navigate]);

  return (
    <div id="deactivate-page">
      <button
        id="back-to-profile-button"
        className=" mb-6 flex items-center"
        onClick={() => navigate('/profileSummary')}
      >
        <img
          id="back-chevron-icon"
          src={chevronLeft}
          alt="Chevron Right"
          className="w-5 h-5"
        />
        <p
          id="back-to-profile-text"
          className="text-primary-500 font-bold ml-2"
        >
          Back to profile
        </p>
      </button>

      <h1 id="deactivate-account-title" className="text-2xl font-bold mb-6">
        Deactivate account
      </h1>

      <p
        id="display-name-description"
        className="font-regular text-neutral-800 mt-4  max-w-6xl"
      >
        To deactivate your MyGRDC account, email us at{' '}
        <a
          href={`mailto:${email}?cc=${userEmail}&subject=${subject}&body=${body}`}
          className="text-blue-500 underline"
          onClick={handleEmailClick}
        >
          subscribers@grdc.com.au
        </a>{' '}
        and let us know your preferred option.
      </p>

      <p className="font-semiBold text-neutral-800 mt-6">
        1. Deactivate MyGRDC account
      </p>

      <p
        id="display-name-description"
        className="font-regular text-neutral-800 pl-4 max-w-6xl"
      >
        You won’t be able to log in, but some preferences (e.g., crops and
        topics) will be saved. If you return to MyGRDC in the future, you will
        need to go through the registration process again. Your details will
        remain in the GRDC database and you will still receive GRDC
        communications if subscribed.
      </p>

      <p className="font-semiBold text-neutral-800 mt-6">
        2. Delete entire GRDC account
      </p>

      <p
        id="display-name-description"
        className="font-regular text-neutral-800 pl-4 max-w-6xl"
      >
        Permanently removes you from the GRDC database including your MyGRDC
        account and preferences. You will no longer receive any GRDC
        communications including GroundCover magazine.
      </p>

      <p
        id="display-name-description"
        className="font-regular text-neutral-800 mt-4  max-w-6xl"
      >
        Let us know which option you'd like, and we’ll take care of it.
      </p>

      <p
        id="display-name-description"
        className="font-regular text-neutral-800 mt-4  max-w-6xl"
      >
        Note: Please contact us using the same email address you used to sign up
        for MyGRDC, so we can verify your details faster.
      </p>
    </div>
  );
};

export default DeactivateAccount;
