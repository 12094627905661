import { getAccessToken } from '@/auth';
import COMMON_PROPERTIES from '@common/properties/common-properties';
import {
  ProfileServiceClient,
  ReferenceInfoRequest,
  ReferenceInfoResponse,
  TopicCategoryInfo,
} from '@common/services/api/profileServices/_profileService';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseAPIUrl = COMMON_PROPERTIES.baseAPIUrl;
const subscriptionKey = COMMON_PROPERTIES.subscriptionKey ?? '';
const client = new ProfileServiceClient(baseAPIUrl + '/profileservice');

export const topicsApi = createApi({
  reducerPath: 'topicsApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getTopics: builder.query<TopicCategoryInfo[], void>({
      queryFn: async (
        _,
      ): Promise<{ data?: TopicCategoryInfo[]; error?: any }> => {
        const token = await getAccessToken();

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const availableTopics = await client.getAvailableTopics(
            token,
            subscriptionKey,
          );
          return { data: availableTopics.map((topic) => topic) };
        } catch (error: any) {
          return {
            error: {
              status: error.response?.status || 500,
              data: error.response?.data || 'Unknown error occurred',
            },
          };
        }
      },
    }),

    getTopicsInfo: builder.query<
      ReferenceInfoResponse,
      ReferenceInfoRequest | null
    >({
      queryFn: async (
        body,
        { getState },
      ): Promise<{ data?: ReferenceInfoResponse; error?: any }> => {
        const token = await getAccessToken();

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const topicsInfo = await client.getTopicsInfo(
            body,
            token,
            subscriptionKey,
          );
          return { data: topicsInfo };
        } catch (error: any) {
          return {
            error: {
              status: error.response?.status || 500,
              data: error.response?.data || 'Unknown error occurred',
            },
          };
        }
      },
    }),
  }),
});

export const { useGetTopicsQuery, useGetTopicsInfoQuery } = topicsApi;
