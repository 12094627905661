import { pca } from './index';
import { loginRequest } from './msal.config';

export const getAccessToken = async (): Promise<string | null> => {
  const accounts = pca.getAllAccounts();
  if (accounts.length === 0) {
    return null;
  }

  try {
    const response = await pca.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });

    if (response.accessToken) {
      return response.accessToken;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
