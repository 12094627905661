import feedImage from '@common/assets/images/mygrdc/feed.svg';
import { useAppSelector } from '@common/hooks/useReduxStore';
import { useSaveUserProfileMutation } from '@common/services/api/profileServices/userProfile';
import { getPersona } from '@common/store/registrationSlice';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RegistrationTitle from './registrationTitle';

const PersonalisingFeed = () => {
  const navigate = useNavigate();
  const persona = useAppSelector(getPersona);
  const [saveUserProfile] = useSaveUserProfileMutation();

  useEffect(() => {
    const userProfilePayload = {
      postcode: persona.postCode?.refText,
      primaryRegionId: persona.primaryRegion?.refId,
      primarySubregionId: persona.primarySubregion?.refId,
      crops: persona.crops?.map((crop) => crop.refId),
      topics: persona.topics?.map((topic) => topic.refId),
      userTypeId: persona.userType?.refId,
      additionalSubregions: persona.additionalSubregions?.map(
        (sub) => sub.refId,
      ),
      profileProcessData: {
        isProfileProcessFinished:
          !persona.profileProcessData?.isProfileProcessFinished,
      },
      notificationData: {
        isNotificationEnabled: !persona.notificationData?.isNotificationEnabled,
      },
    };

    const saveProfile = async () => {
      try {
        await saveUserProfile(userProfilePayload).unwrap();
      } catch (error) {
        console.error('Error saving profile:', error);
      }
    };

    saveProfile();

    setTimeout(() => {
      navigate('/', { replace: true });
    }, 4000);
  }, [navigate, persona, saveUserProfile]);

  return (
    <div className="flex flex-col items-center justify-center flex-1 gap-8">
      <RegistrationTitle title="Personalising your dashboard..." />
      <img src={feedImage} alt="Feed" width={200} className="animate-pulse" />
    </div>
  );
};

export default PersonalisingFeed;
