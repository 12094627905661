import COMMON_PROPERTIES from '@common/properties/common-properties';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getAccessToken } from '@/auth';
import {
  AvailableFacetRequest,
  ContentSearchServiceClient,
  SearchResponseBasic,
} from '@common/services/api/searchServices/_contentSearchService';
import { data } from 'autoprefixer';

const baseAPIUrl = COMMON_PROPERTIES.baseAPIUrl;
const subscriptionKey = COMMON_PROPERTIES.subscriptionKey ?? '';
const client = new ContentSearchServiceClient(
  baseAPIUrl + '/contentsearchservice',
);

export const facetsApi = createApi({
  reducerPath: 'facetsApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getAvailableFacets: builder.query<
      SearchResponseBasic,
      AvailableFacetRequest
    >({
      queryFn: async (
        body,
      ): Promise<{ data?: SearchResponseBasic; error?: any }> => {
        const token = await getAccessToken();

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const facetResponse = await client.getAvailableFacets(
            body,
            token,
            subscriptionKey,
          );
          console.log('available facets', data);

          return { data: facetResponse };
        } catch (error: any) {
          return {
            error: {
              status: error.response?.status || 500,
              data: error.response?.data || 'Unknown error occurred',
            },
          };
        }
      },
    }),
  }),
});

export const { useGetAvailableFacetsQuery } = facetsApi;
