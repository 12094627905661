import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getAccessToken } from '@/auth';
import COMMON_PROPERTIES from '@common/properties/common-properties';
import {
  ProfileServiceClient,
  ReferenceInfoRequest,
  ReferenceInfoResponse,
  UserTypeInfo,
} from '@common/services/api/profileServices/_profileService';

const baseAPIUrl = COMMON_PROPERTIES.baseAPIUrl;
const subscriptionKey = COMMON_PROPERTIES.subscriptionKey ?? '';
const client = new ProfileServiceClient(baseAPIUrl + '/profileservice');

export const userTypesApi = createApi({
  reducerPath: 'userTypesApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getUserTypes: builder.query<UserTypeInfo[], void>({
      queryFn: async (
        _,
        { getState },
      ): Promise<{ data?: UserTypeInfo[]; error?: any }> => {
        const token = await getAccessToken();

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const userTypes = await client.getAvailableUserTypes(
            token,
            subscriptionKey,
          );
          return { data: userTypes.map((userType) => userType) };
        } catch (error: any) {
          return {
            error: {
              status: error.response?.status || 500,
              data: error.response?.data || 'Unknown error occurred',
            },
          };
        }
      },
    }),

    getUserTypeInfo: builder.query<
      ReferenceInfoResponse,
      ReferenceInfoRequest | null
    >({
      queryFn: async (
        body,
      ): Promise<{ data?: ReferenceInfoResponse; error?: any }> => {
        const token = await getAccessToken();

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const userTypeInfo = await client.getUserTypeInfo(
            body,
            token,
            subscriptionKey,
          );
          return { data: userTypeInfo };
        } catch (error: any) {
          console.log('Error fetching user type info:', error);
          return {
            error: {
              status: error?.response?.status || 500,
              data: error?.response?.data || 'Unknown error occurred',
            },
          };
        }
      },
    }),
  }),
});

export const { useGetUserTypesQuery, useGetUserTypeInfoQuery } = userTypesApi;
