import clsx from 'clsx';

interface OptionCardProps {
  label: string | undefined;
  name: string | undefined;
  value: string | readonly string[] | undefined;
  checked: boolean | undefined;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

const OptionCard: React.FC<OptionCardProps> = ({
  label,
  name,
  value,
  checked,
  onChange,
}) => {
  return (
    <label
      className={clsx(
        'flex items-center border-2 rounded-lg p-2 cursor-pointer',
        checked
          ? 'bg-primaryTeal-100 border-primary-400'
          : 'bg-white border-primaryTeal-200',
      )}
    >
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="hidden"
      />
      <div className="flex items-center justify-center w-5 h-5 mr-4 border-2 border-green-600 rounded-full">
        {checked && <div className="w-2 h-2 bg-green-600 rounded-full"></div>}
      </div>
      <span className="font-light">{label ?? 'Unknown'}</span>
    </label>
  );
};

export default OptionCard;
